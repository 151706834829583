import { FETCH_CART, RESET_CART } from '../Constants'

const initialState = []

function cartReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CART:
            return action.payload
        case RESET_CART:
            return action.payload
        default:
            return state
    }
}


export default cartReducer