import React from 'react'

import generalCss from '../../General Functions/GeneralCss'

// material ui imports
import {
    Button, makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: 'none',
        // backgroundColor:,
        backgroundImage: `linear-gradient(to right,${theme.palette.primary.light},${theme.palette.primary.dark})`,
        borderRadius: '30px',
        width: '100%',
        fontSize: "18px",
        fontWeight: 600,
        border: `1px solid ${generalCss.buttonTextColor}`,
        color: 'white',
        '&:hover': {
            backgroundImage: `linear-gradient(to right,${theme.palette.primary.light},${theme.palette.primary.dark})`, 
        }
    },
    disabled:{
        backgroundColor:'white',
        backgroundImage: 'none'
    }
}))

function ButtonComponent(props) {
    const classes = useStyles()
    return (
        <Button
            {...props}
            classes={{disabled:classes.disabled}}
            className={classes.button}
        >
            {props.children}
        </Button>
    )
}

export default ButtonComponent