import React, { useState } from 'react'
//localization imports
import { useHistory, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DialogBoxComponent from '../../materialUI/generalComponents/DialogBoxComponent'
import GradientStyledButtonComponent from '../../materialUI/generalComponents/WebGradientButton'
import AppRoutes from '../../General Functions/Routes'
import {
    DialogContent, DialogActions, makeStyles, Grid, Typography, IconButton, MenuItem, Hidden
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Cancel'


const useStyles = makeStyles(theme => ({
    iconButton: {
        border: '1px solid black',
        padding: '5px',
    },
    iconButtonDisabled: {
        border: '1px solid lightgray',
    },
    refreshButton: {
        borderRadius: '50%',
        backgroundColor: 'white',
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.26) !important",
    },
    refreshIcon: {
        transform: 'scaleX(-1)'
    },
    labels: {
        fontSize: '13px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px'
        }
    },
    subText:{
        textAlign:'center'
    }
}))

function UserBlock(props) {
    const classes = useStyles()
    const { location,onClose,open } = props
    const { t } = useTranslation('translations')
    const history = useHistory()


    // const onClose = () => {
    //     history.push(AppRoutes.home)
    // }


    return (
        <DialogBoxComponent open={open} close={onClose} title={t("amaco.userBlock.title", "Message from Amaco")} >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item className={classes.subText} xs={12} >
                        <ClearIcon style={{ fontSize: '80px', color: 'red' }} />
                    </Grid>
                    <Grid item className={classes.subText} xs={12}>
                        <Typography>{t("amaco.signin.userError", "Your account has been blocked. Please contact admin")}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2} style={{ padding: '10px' }} alignItems='center' justify='center'>
                    <Grid item xs={12} sm={6}>
                        <GradientStyledButtonComponent
                            onClick={() => onClose()}>{t("amaco.userBlock.button", "Ok")}</GradientStyledButtonComponent>
                    </Grid>

                </Grid>
            </DialogActions>
        </DialogBoxComponent>
    )
}



export default withRouter(UserBlock)