import React from 'react'
import {useTranslation} from 'react-i18next'
import {
    Grid, CircularProgress, Typography, makeStyles
} from '@material-ui/core'
import Logo from '../../assets/logo192.png'

const useStyles = makeStyles(theme => ({
    container:{
        width:'100vw',
        height:'100vh',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'100%'
        }
    },
    '@keyframes inOut': {
        from: {
            transform: 'scale(1)'
        },
        to: {
            transform: 'scale(1.1)'
        },
    },
    inOut: {
        animationName: '$inOut',
        animationDuration: '500ms',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out',
    },
    text:{
        fontSize:'14px',
        fontWeight:600,
        color: theme.palette.primary.main
    }
}))

function SuspenseLoader() {
    const classes = useStyles()
    const {t} = useTranslation('translations')
    return (
        <Grid container spacing={1} className={classes.container} direction='column' alignItems='center' justify='center'>
            <Grid item style={{ textAlign: 'center' }} >
                <img className={classes.inOut} src={Logo} style={{ width: "60px", height: 'auto' }} />
            </Grid>
            <Grid item >
                <Typography className={classes.text}>{t("amaco.loading","Loading...")}</Typography>
            </Grid>
        </Grid>
    )
}

export default SuspenseLoader
