import { combineReducers } from 'redux';
import {LOGOUT} from '../Constants'
import cartReducer from './cartReducer';
import configurationReducer from './configurationReducer';
import countryCodeReducer from './countryCodeReducer';
import directionReducer from './directionReducer';
import equipmentCategoryReducer from './equipmentCategoryReducer';
import equipmentReducer from './equipmentReducer';
import informationPageReducer from './informationPageReducer';
import locationReducer from './locationReducer';
import mainCategoryReducer from './mainCategoryReducer';
import orderByIdReducer from './orderByIdReducer';
import orderReducer from './orderReducer';
import serviceCategoryReducer from './serviceCategoryReducer';
import servicesReducer from './servicesReducer';
import singleEquipmentReducer from './singleEquipmentReducer'
import singleServiceReducer from './singleServiceReducer';
import totalOrderByIdReducer from './totalOrderByIdReducer';
import userDetailsReducer from './userDetailsReducer';
import uIdReducer from './userUidReducer';

const appReducers = combineReducers({
    equipments: equipmentReducer,
    equipment: singleEquipmentReducer,
    equipmentCategories: equipmentCategoryReducer,
    uId: uIdReducer,
    user: userDetailsReducer,
    cart: cartReducer,
    direction: directionReducer,
    orders: orderReducer,
    order: orderByIdReducer,
    orderById: totalOrderByIdReducer,
    locations: locationReducer,
    services: servicesReducer,
    service: singleServiceReducer,
    serviceCategories: serviceCategoryReducer,
    mainCategories: mainCategoryReducer,
    countryCode: countryCodeReducer,
    configurations: configurationReducer,
    informationPages: informationPageReducer
})

const allReducers = (state,action) => {
    if(action.type === LOGOUT){
        state = undefined
    }
    return appReducers(state,action)
}

export default allReducers