import firebase from '../../firebase/config'
import {FETCH_CART,RESET_CART} from '../Constants'
import {cartCollection,cartActiveStatus} from '../../firebase/Constants'


export const fetchCart = (uId) => dispatch => {
    const unsubscribe = firebase.firestore().collection(cartCollection).where('cartId','==',uId).where('status','==',cartActiveStatus).onSnapshot(snapshot => {
        let equipments = []
        snapshot.forEach(doc => {
            const data = doc.data()
            equipments.push(data)
        })
        dispatch({
            type: FETCH_CART,
            payload: equipments
        })
    })
    return unsubscribe;
}
export const resetCart = () => {
    return{
        type: RESET_CART,
        payload:[]
    }
}