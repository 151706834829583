import {FETCH_TOTAL_ORDER_BY_ID} from '../Constants'

const initialState = {}

function totalOrderByIdReducer(state=initialState,action){
    switch(action.type){
        case FETCH_TOTAL_ORDER_BY_ID:
            return action.payload
        default: 
            return state
    }
}


export default totalOrderByIdReducer