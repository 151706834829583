export const LOGOUT = 'LOGOUT'
export const FETCH_EQUIPMENTS = 'FETCH_EQUIPMENTS'
export const FETCH_SINGLE_EQUIPMENT = 'FETCH_SINGLE_EQUIPMENT'
export const FETCH_EQUIPMENT_CATEGORIES  = 'FETCH_EQUIPMENT_CATEGORIES'
export const SET_USER_UID = 'SET_USER_UID'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const FETCH_CART = 'FETCH_CART'
export const DIRECTION = 'DIRECTION'
export const FETCH_SERVICES = 'FETCH_SERVICES'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID'
export const FETCH_TOTAL_ORDER_BY_ID = 'FETCH_TOTAL_ORDER_BY_ID'
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_SINGLE_SERVICE = 'FETCH_SINGLE_SERVICE'
export const FETCH_SERVICE_CATEGORIES  = 'FETCH_SERVICE_CATEGORIES'
export const FETCH_MAIN_CATEGORIES = 'FETCH_MAIN_CATEGORIES'
export const FETCH_COUNTRY_CODES = 'FETCH_COUNTRY_CODES'
export const FETCH_CONFIGURATIONS = 'FETCH_CONFIGURATIONS'
export const RESET_CART = 'RESET_CART'
export const RESET_SINGLE_EQUIPMENT = 'RESET_SINGLE_EQUIPMENT'
export const RESET_SINGLE_SERVICE = 'RESET_SINGLE_SERVICE'
export const FETCH_INFORMATION_PAGES = 'FETCH_INFORMATION_PAGES'