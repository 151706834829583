const Routes = {
    home: '/home',
    equipments : '/equipments',
    services: '/services',
    information: '/information',
    signin:'/sign-in',
    signup:'/sign-up',
    cart: '/order',
    order:'/order',
    profile:'/profile',
    contact:'/contact',
    about: '/about-us',
    faq:'/faq',
    privacyPolicy: '/privacy-policy',
    termsConditions: '/terms-and-conditions'
}

export default Routes