import firebase from '../../firebase/config'
import {FETCH_COUNTRY_CODES} from '../Constants'
import {countryCodeCollection} from '../../firebase/Constants'


export const fetchCodes = () => dispatch => {
    firebase.firestore().collection(countryCodeCollection).get().then(snapshot => {
        let equipments = []
        snapshot.forEach(doc => {
            const data = doc.data()
            equipments.push(data)
        })
        dispatch({
            type: FETCH_COUNTRY_CODES,
            payload: equipments
        })
    })
}