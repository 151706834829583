const generalCss = {
    cardBackgroundGrey : '#f3f3f3',
    cardBackgroundGreyDark : '#cdcdcd',
    priceTextColor:'#2dc08a',
    gridCardPlusBackground:'#ffffff',
    buttonTextColor:'#676e76',
    labelColor:'#9a9ea1',
    chipColor:'#4e5254',
    footerColor: '#9695a7',
    profileBackground:'#f5f6f8'
}

export default generalCss