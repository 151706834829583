import React from 'react'

import generalCss from '../../General Functions/GeneralCss'

import {
    Dialog, DialogTitle, makeStyles, IconButton, Hidden, Grid,Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogTab: {
        width: '100vw',
        maxWidth:'100% !important',
        borderRadius: '20px 20px 0 0',
        position: 'absolute !important',
        left:0,
        bottom: 0,
        margin: '0px'
    },
    dialogContainer: {
        width: 'auto',
        borderRadius: '20px',
        position: 'relative',
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            width: '500px'
        }
    },
    IconButton: {
        padding: '15px',
        backgroundColor: generalCss.cardBackgroundGrey,
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: '0 20px 0 20px',
        '&: hover': {
            backgroundColor: generalCss.cardBackgroundGreyDark
        }
    },
    icon: {
        fontSize: '24px'
    }
}))

function DialogComponent(props) {
    const classes = useStyles()
    return (
        <>
            <Hidden xsDown implementation='js'>
                <Dialog
                    scroll='body'
                    TransitionComponent={Transition}
                    classes={{ paper: classes.dialogContainer }}
                    open={props.open} onClose={props.close}

                >
                    <DialogTitle>
                        <Grid container justify='space-between'>
                            <Grid item>
                                {props.title}
                            </Grid>
                            <Grid item>
                                <IconButton className={classes.IconButton} onClick={props.close}>
                                    <CloseIcon className={classes.icon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {props.children}
                </Dialog>
            </Hidden>
            <Hidden smUp implementation='js'>
                <Dialog
                    PaperProps={{
                        className: classes.dialogTab
                    }}
                    TransitionComponent={Transition}
                    scroll='body'
                    // classes={{ paper: classes.dialogContainer }}
                    open={props.open} onClose={props.close}

                >
                    <DialogTitle>
                        <Grid container justify='space-between'>
                            <Grid item>
                                {props.title}
                            </Grid>
                            <Grid item>
                                <IconButton className={classes.IconButton} onClick={props.close}>
                                    <CloseIcon className={classes.icon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    {props.children}
                </Dialog>
            </Hidden>
        </>
    )
}

export default DialogComponent