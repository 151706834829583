import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n'
import App from './App';
import * as serviceworker from './serviceWorker'
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk'
import { Provider } from 'react-redux';
import allReducers from './projectFiles/store/reducers/allReducer';
import { BrowserRouter as Router } from 'react-router-dom'

const store = createStore(allReducers, applyMiddleware(reduxThunk))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <Router>
        <App />
      </Router>

    </Suspense>

  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceworker.register()
