import React, { lazy, Suspense, useEffect } from 'react'
import { Redirect, BrowserRouter as Router, Route, Switch, withRouter, useHistory } from 'react-router-dom'

import firebase from './projectFiles/firebase/config'
import { userCollection } from './projectFiles/firebase/Constants'
import { setUserUid } from './projectFiles/store/actions/uIdAction'
import { setUserDetails } from './projectFiles/store/actions/userAction'
import { fetchCart, resetCart } from './projectFiles/store/actions/cartAction'
import { fetchCodes } from './projectFiles/store/actions/countryCodeAction'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SuspenseLoader from './projectFiles/materialUI/generalComponents/FallbackSuspenseLoader'
// route import
import AppRoutes from './projectFiles/General Functions/Routes'

// general functions
import ChunkLoading from './projectFiles/General Functions/ChunkLoading'
import Console from './projectFiles/General Functions/Console'

// material ui imports
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import Theme from './projectFiles/materialUI/Theme'
import UserBlock from './projectFiles/components/MainApp/UserBlock'

// component loading
const SignUp = lazy(() => ChunkLoading(() => import('./projectFiles/components/SignUp')))
const SignIn = lazy(() => ChunkLoading(() => import('./projectFiles/components/SignIn')))
const MainApp = lazy(() => ChunkLoading(() => import('./projectFiles/components/MainApp')))
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

let cartUnsubscribe, userUnsubscribe;
let counter = 0

function App(props) {
  const { setUserUid, setUserDetails, fetchCart, direction, fetchCodes, resetCart, location } = props
  window.version = process.env.REACT_APP_VERSION
  const [open,setOpen] = React.useState(false)
  useEffect(() => {
    fetchCodes()
    firebase.auth().onAuthStateChanged(user => {
      // console.log(user)
      if (user) {
        // console.log('run again')
        const uId = user.uid
        const isAnonymous = user.isAnonymous
        setUserUid({ uId, isAnonymous })
        cartUnsubscribe && cartUnsubscribe()
        cartUnsubscribe = fetchCart(uId)
        if (!isAnonymous) {
          userUnsubscribe = firebase.firestore().collection(userCollection).doc(uId).onSnapshot((doc) => {
            if (doc.exists) {
              const data = doc.data()
              if (data.blocked) {
                firebase.auth().signOut().then(() => {
                  counter = 0
                 setOpen(true)
                })
              } else {
                setUserDetails(data)
                if (firebase.messaging.isSupported()) {
                  console.log('messaging supported')
                  firebase.messaging().requestPermission().then(() => {
                    firebase.messaging().getToken().then((token) => {
                      console.log('got token')
                      const userAgent = navigator ? navigator.userAgent : ''
                      counter += 1
                      if (counter <= 1) {
                        // console.log('I am run')
                        firebase.firestore().collection(userCollection).doc(uId).update({
                          fcmTokens: firebase.firestore.FieldValue.arrayUnion(token),
                          appVersion: process.env.REACT_APP_VERSION,
                          userAgent: userAgent
                        }).then(() => {
                          // Console('token added')
                          console.log('token added')
                        }).catch(err => {
                          console.log(err)
                          Console(err)
                        })
                      }
                    }).catch(err => {
                      Console(err)
                      console.log(err)
                    })
                  }).catch(err => Console(err))
                } else {
                  console.log('messaging not supported')
                }
              }

            } else {
              counter = 0
              setUserDetails({ id: uId, email: null, phone: user.phoneNumber, name: null, uId: uId })
            }

          })
          // .then(() => {
          // }).catch(err => {
          //   Console(err)
          // })

        } else {
          counter = 0
          setUserDetails({ id: uId, email: null, phone: null, name: null, uId: uId })
        }
      } else {
        counter = 0
        setUserDetails({ id: null, email: null, phone: null, name: null, uId: null })
        setUserUid({ uId: null, isAnonymous: true })
        resetCart()
        cartUnsubscribe && cartUnsubscribe()
        userUnsubscribe && userUnsubscribe()
        // firebase.auth().signInAnonymously().catch(err => { Console(err) })
      }
    })
    return () => {
      cartUnsubscribe && cartUnsubscribe()
      userUnsubscribe && userUnsubscribe()
    }
  }, [])

  return (
    <ThemeProvider theme={createMuiTheme({ ...Theme, direction: direction })}>
      <StylesProvider jss={jss}>
        <Suspense fallback={<div style={{ width: '100vw', height: '100vh' }}><SuspenseLoader /></div>}>
          <Router>
            <Switch>
              <Route exact path={AppRoutes.signup} component={SignUp}></Route>
              <Route exact path={AppRoutes.signin} component={SignIn}></Route>
              <Route path='*' component={MainApp}></Route>
            </Switch>
            <UserBlock open={open} onClose={() => setOpen(false)} />
          </Router>
        </Suspense>
      </StylesProvider>

    </ThemeProvider>

  );
}

const mapStateToProps = state => {
  return {
    direction: state.direction
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserUid: (value) => dispatch(setUserUid(value)),
    setUserDetails: (value) => dispatch(setUserDetails(value)),
    fetchCart: (value) => dispatch(fetchCart(value)),
    fetchCodes: () => dispatch(fetchCodes()),
    resetCart: () => dispatch(resetCart())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(App);
